import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Services from '~components/services'
import Projects from '~components/projects'
import About from '~components/about'
import Contact from '~components/contact'

const Wrapper = styled.div`
  ${
    props => {
      if (props.animate) {
        return `
          opacity: 0;
          animation-name: fade-in;
          animation-duration: 2s;
          animation-delay: 1.5s;
          animation-iteration-count: 1;
          animation-timing-function: ease-in;
          animation-fill-mode: forwards;

          @keyframes fade-in {
            0% {
              opacity: 0;
            }
            5% {
              opacity: .05;
            }
            10% {
              opacity: .1;
            }
            15% {
              opacity: .15;
            }
            20% {
              opacity: .2;
            }
            25% {
              opacity: .25;
            }
            30% {
              opacity: .3;
            }
            35% {
              opacity: .35;
            }
            40% {
              opacity: .4;
            }
            45% {
              opacity: .45;
            }
            50% {
              opacity: .5;
            }
            55% {
              opacity: .55;
            }
            60% {
              opacity: .6;
            }
            65% {
              opacity: .65;
            }
            70% {
              opacity: .7;
            }
            75% {
              opacity: .75;
            }
            80% {
              opacity: .8;
            }
            85% {
              opacity: .85;
            }
            90% {
              opacity: .90;
            }
            95% {
              opacity: .95;
            }
            100% {
              opacity: 1;
            }
          }
        `
      }
    }
  }
`

function Main({
  article,
  animate,
}){
  return (
    <Wrapper animate={animate} id="main">
      {
        article === 'services' ? (
          <Services />
        ) : null
      }
      {
        article === 'projects' ? (
          <Projects />
        ) : null
      }
      {
        article === 'careers' ? (
          <Projects />
        ) : null
      }
      {
        article === 'about-us' ? (
          <About />
        ) : null
      }
      {
        article === 'contact' ? (
          <Contact />
        ) : null
      }
    </Wrapper>
  )
}

Main.propTypes = {
  article: PropTypes.string,
}

export default Main
