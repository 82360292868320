import React from 'react'
import styled from 'styled-components'
import jim from '~images/jim.jpg'
import zach from '~images/zach.jpg'
import caraCrop from '~images/caraCrop.jpg'

const ImageContainer = styled.div`
  display: flex;
  background-color: white;
  border-radius: 4px;
  padding: 1rem 2.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  color: black;
  justify-content: space-around;
  flex-wrap: wrap;

  @media (max-width: 800px) {
    justify-content: none;
  }
`

const Profile = styled.img`
  height: auto;
  width: 160px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 800px) {
    height: auto;
    width: 140px;
    margin-left: auto;
    margin-right: auto;
  }
`

const ProfileCap = styled.figcaption`
  text-align: center;
  position: relative;
  bottom: 10px;
  
  font-size: 1.1rem;

  @media (max-width: 800px) {
    bottom: 10px;
  }
`

const ProfileCapTitle = styled.figcaption`
  text-align: center;
  position: relative;
  bottom: 18px;
  font-size: 1rem;

  @media (max-width: 800px) {
    margin-bottom: 15px;
    bottom: 15px;
  }
`

export default () => (
  <article id="about">
    <p>We are a group of highly skilled and seasoned software engineers who have built products for companies like Sony PlayStation, Gracenote, and Marriott. From developing a music player demonstrated at CES to creating an enterprise grade search service supporting millions of hits per day we have the skills and expertise to solve your problems big or small.  We love the thrill of turning complex problems into elegant software solutions.</p>    
  </article>
)
