import React, { Component } from 'react'
import { findDOMNode } from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Logo from '~assets/falcon.svg'
import { device } from '~utils/breakpoints'

const AnimatedLogo = styled(Logo)`
  animation: spin 7s linear infinite;

  @keyframes spin {
    0% { transform: rotateY(180deg); }
    50% { transform: none; }
  }
`

const InnerHeader = styled.header`
  ${
    props => {
      if (props.article != null && props.article !== '') {
        return `
          &#header {
            ${props.animate ? 'transition: margin-top 1s, width 1s ease-in-out !important;' : ''}
            ${props.animate ? 'transition-delay: 1.5s !important;' : ''}
            margin-top: 40px;
            width: 95%;

            .logo {
              ${props.animate ? 'transition: transform 1s ease-in-out !important;' : ''}
              transform: translate(-195px, calc(${props.logoOffsetTop}px + 170px)) scale(.5);

              @media ${device.tablet} {
                transform: translateY(calc(${props.logoOffsetTop}px + 70px)) scale(.5);
              }
            }

            .content {
              ${props.animate ? 'transition: margin-top 1s, border-top-style 2s ease-in-out !important;' : ''}
              ${props.animate ? 'transition-delay: .5s !important;' : ''}
              border-bottom: none;
              border-top-style: dashed;
              margin-top: -80px;

              @media ${device.tablet} {
                margin-top: -25px;
              }

              &:before {
                content: '';
                display: none;
              }

              .inner {
                ${props.animate ? 'transition: max-height 1s, padding 1s, margin 1s ease-in-out !important;' : ''}
                ${props.animate ? 'transition-delay: .5s !important;' : ''}
                overflow: visible;
                max-height: 1px;
                padding: 0;
                margin: 0;

                h1 {
                  ${props.animate ? ' transition: transform 1s ease-in-out !important;' : ''}
                  transform: translateY(calc(${props.titleOffsetTop}px + 205px + 145px));

                  @media ${device.tablet} {
                    transform: translateY(calc(${props.logoOffsetTop}px + 80px));
                  }
                }

                > p {
                  ${props.animate ? 'transition: height 1s, opacity 1s ease-in-out !important;' : ''}
                  height: 0;
                  opacity: 0;
                  overflow: hidden;
                }
              }
            }

            nav {
              &:before {
                content: '';
                display: none;
              }

              margin-left: auto;
              margin-right: auto;

              transform: translateY(calc(${props.titleOffsetTop}px + 185px + 155px));

              ul {
                border: none;

                li {
                  border: none;
                }
              }

              @media ${device.tablet} {
                display: none;

                ${
                  props.showmenu ? `
                    display: block;
                    transform: translateY(calc(${props.titleOffsetTop}px + 185px + 75px));
                  ` : ''
                }
              }
            }
          }
        `
      }
    }
  }
`

const Hamburger = styled.i`
  cursor: pointer;
  font-size: 24px;
  padding: 1.25rem;
  display: none;

  @media ${device.tablet} {
    display: block;

    ${props => {
      if (props.showmenu) {
        return 'display: none;'
      }
    }}
  }
`

const Close = styled.div`
  display: none;

  ${props => {
    if (props.showmenu) {
      return 'display: block;'
    }
  }}
`

const Anchor = styled.a`
  ${
    props => {
      if (props.active) {
        return `
          background-color: rgba(255, 255, 255, 0.175);
        `
      }
    }
  }
`

class Header extends Component {
  state = {}

  componentDidMount() {
    const node = findDOMNode(this)
    const logoNode = node.querySelector('.logo')
    const titleNode = node.querySelector('.inner h1')
    const logoRect = logoNode.getBoundingClientRect()
    const titleRect = titleNode.getBoundingClientRect()

    this.setState({
      logoOffsetTop: -logoRect.top,
      titleOffsetTop: -titleRect.top,
    })
  }

  render() {
    const { openArticle, closeArticle, animate, article } = this.props
    return (
      <React.Fragment>
        <InnerHeader
          id="header"
          article={article}
          animate={animate}
          logoOffsetTop={this.state.logoOffsetTop}
          titleOffsetTop={this.state.titleOffsetTop}
          showmenu={this.state.showmenu}
        >
          <div className="logo">
            <AnimatedLogo />
          </div>
          <div className="content">
            <div className="inner">
              <a href="">
                <h1>falcon.code</h1>
              </a>
              <p>Software made with speed and precision</p>
            </div>
          </div>
          <nav>
            <ul>
              <li>
                <Anchor
                  href="#services"
                  active={article === 'services'}
                  onClick={() => {
                    openArticle('services')
                    this.setState({ showmenu: false })
                  }}
                >
                  Services
                </Anchor>
              </li>
              <li>
                <Anchor
                  href="#projects"
                  active={article === 'projects'}
                  onClick={() => {
                    openArticle('projects')
                    this.setState({ showmenu: false })
                  }}
                >
                  Projects
                </Anchor>
              </li>
              <li>
                <Anchor
                  href="#about-us"
                  active={article === 'about-us'}
                  onClick={() => {
                    openArticle('about-us')
                    this.setState({ showmenu: false })
                  }}
                >
                  About Us
                </Anchor>
              </li>
              {/* <li>
                <Anchor
                  href="#contact"
                  active={article === 'contact'}
                  onClick={() => {
                    openArticle('contact')
                    this.setState({ showmenu: false })
                  }}
                >
                  Contact
                </Anchor>
              </li> */}
            </ul>
          </nav>
        </InnerHeader>
        {
          article ? (
            <Hamburger
              className="fa fa-bars hamburger"
              onClick={() => {
                closeArticle()
                this.setState({ showmenu: true })
              }}
              showmenu={this.state.showmenu}
            />
          ) : null
        }
        {
          article ? (
            <Close
              className="close"
              onClick={() => {
                this.setState({ showmenu: false })
                openArticle(article)
              }}
              showmenu={this.state.showmenu}
            />
          ) : null
        }
      </React.Fragment>
    )
  }
}

Header.propTypes = {
  openArticle: PropTypes.func,
}

export default Header
