import React from 'react'
import styled from 'styled-components'
import pic01 from '~images/pic01.jpg'

const ImageContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 4px;
  padding: 1rem 2.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;

  img {
    width: 100%;
  }
`

export default () => (
  <article id="intro">
    <ImageContainer>
      <img src={pic01} alt="technologies" />
    </ImageContainer>
    <p>Services we currently offer here at falcon.code</p>
    <ul>
      <li>Custom web and mobile application development</li>
      <li>JavaScript and TypeScript support</li>      
    </ul>
    <p><a href="#contact">Contact</a> for more information</p>
  </article>
)
