import React from 'react'

import Layout from '~components/layout'
import Header from '~components/header'
import Main from '~components/main'
import Footer from '~components/footer'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      article: '',
      loading: 'is-loading',
    }
  }

  componentDidMount () {
    window.addEventListener('hashchange', this.hashHandler)

    if (this.props.location.hash) {
      this.setState({
        disableAnimation: true,
        article: this.props.location.hash.slice(1),
        loading: '',
      })
    } else {
      this.loadingTimeoutId = setTimeout(() => {
        this.setState({ loading: '' })
      }, 100);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.disableAnimation && this.state.article && !this.disableAnimationTimeoutId) {
      this.disableAnimationTimeoutId = setTimeout(() => {
        this.setState({
          disableAnimation: true,
        })
      }, 5000)
    }
  }

  componentWillUnmount () {
    if (this.loadingTimeoutId) {
      clearTimeout(this.loadingTimeoutId);
    }
    window.removeEventListener('hashchange', this.hashHandler)
  }

  openArticle = article =>
    this.setState({
      article,
      hideArticle: false,
    })

  closeArticle = () =>
    this.setState({
      hideArticle: true,
    })

  hashHandler = () => {
    this.openArticle(window.location.hash.slice(1))
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <div className={`body ${this.state.loading}`}>
          <div id="wrapper">
            <Header
              openArticle={this.openArticle}
              closeArticle={this.closeArticle}
              article={this.state.article}
              animate={this.state.article && !this.state.disableAnimation}
            />
            {
              !!this.state.article && !this.state.hideArticle ? (
                <Main
                  article={this.state.article}
                  animate={this.state.article && !this.state.disableAnimation}
                />
              ) : null
            }
            <Footer />
          </div>
          <div id="bg"></div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
