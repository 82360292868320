import React from 'react'
import styled from 'styled-components'
import debigniter from '~assets/debigniter.svg'

const DebIgniterContainer = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 4px;
  padding: 1rem 2.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1rem;

  img {
    width: 100%;
  }
`

export default () => (
  <article id="work">
    <a href="https://www.debtigniter.com">
      <DebIgniterContainer>
        <img src={debigniter} alt="Debt Igniter" />
      </DebIgniterContainer>
    </a>
    <p>Coming soon</p>
    <p>
      Debt Igniter, is a cutting edge finance tracking and projection system.
    </p>
    <p>
      Using artificial intelligence and machine learning to help algorithmically eliminate your debt
    </p>
  </article>
)
