import React from 'react'
import styled from 'styled-components'


const Social = styled.ul`
  display: inline-block;
`

const Number = styled.div`
  margin-left: 180px;
  display: inline-block;
  font-size: 1.2em;
  vertical-align: top;
  position: relative;
  bottom: 40px;
  

  @media(max-width: 800px) {
    margin-left: 8px;
    bottom: 0;
  }
`

const EMail = styled.div`
  margin-left: 50px;
  display: inline-block;
  font-size: 1.2em;
  vertical-align: top;

  @media(max-width: 800px) {
    margin-left: 8px;
  }
`


export default () => (
  <article id="contact_page">
    <form method="post" action="https://formspree.io/xzkonjzm">
      <div className="field half first">
        <label htmlFor="name">Name</label>
        <input type="text" name="name" id="name" />
      </div>
      <div className="field half">
        <label htmlFor="email">Email</label>
        <input type="text" name="email" id="email" />
      </div>
      <div className="field">
        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" rows="4"></textarea>
      </div>
      <ul className="actions">
        <li><input type="submit" value="Send Message" className="special" /></li>
        <li><input type="reset" value="Reset" /></li>
      </ul>
    </form>
    <Social className="icons">
      <li><a href="https://twitter.com/falconcode1" className="icon fa-twitter" target="_blank" rel="noopener noreferrer"><span className="label">Twitter</span></a></li>
      <li><a href="https://www.facebook.com/falconcodegroup/" className="icon fa-facebook" target="_blank" rel="noopener noreferrer"><span className="label">Facebook</span></a></li>
      <li><a href="https://www.instagram.com/falcon.code/" className="icon fa-instagram" target="_blank" rel="noopener noreferrer"><span className="label">Instagram</span></a></li>
      {/* <li><a href="#" className="icon fa-github"><span className="label">GitHub</span></a></li> */}
    </Social>
    <EMail><a href = "mailto: hello@falconcode.group"> hello@falconcode.group</a></EMail>
    <Number>(207)387-3390</Number>
  </article>
)
